import React, { Component } from 'react';
import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import "./Banner.css";
import Contact from '../Home/Contact';

class Banner extends Component {

    render() {
        return (
            <React.Fragment>
                <div id="home" className="main-banner ban">
                    <div className="d-table">
                        <div className="d-table-cell">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="main-banner-content cont">
                                            <h1>
                                                WMotor Chile <span className="registred">®</span><br/>Logística y Servicios
                                            </h1>
                                            <h2>
                                                Rectificación de Motores.
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <Contact />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default Banner;
