import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel3";
import "./About.css";
const options = {
  loop: true,
  nav: false,
  dots: false,
  autoplayHoverPause: true,
  autoplayTimeout: 1000,
  autoplay: true,
  navText: [
    "<i class='fas fa-angle-left'></i>",
    "<i class='fas fa-angle-right'></i>",
  ],
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
    1200: {
      items: 3,
    },
  },
};

class About extends Component {
  renderService(text, img, i) {
    return (
      <div key={i} className="col-lg-12 col-md-12">
        <div class="cont">
          <div class="thumbex">
            <div class="thumbnail">
              <img src={img} alt="Servicio" />
              <span>
                <h3>{text}</h3>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const services = [
      {
        name: "Servicio Personalizado",
        image: require("../../images/service/Culata.jpg"),
      },
      {
        name: "Presupuesto Gratuito",
        image: require("../../images/service/presupuestogratuito.jpg"),
      },
      {
        name: "Honestidad en los cobros",
        image: require("../../images/service/honestidad.jpg"),
      },
      {
        name: "Responsabilidad y Puntualidad",
        image: require("../../images/service/resposabilidad.jpg"),
      },
      {
        name: "Personal Calificado",
        image: require("../../images/equipo.jpg"),
      },
      {
        name: "Artículos de Calidad",
        image: require("../../images/service/articulos.jpeg"),
      },
      {
        name: "Servicio en Terreno",
        image: require("../../images/service/terreno.jpg"),
      },
    ];
    return (
      <section id="about" className="about-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-md-6 ">
              <div className="saas-section-title quienes-somos">
                <div className="bar"></div>
                <h2>Quienes Somos</h2>
                <div className="bar"></div>
                <p>
                  <b>
                    WMotorChile Logística y Servicios, Rectificación de Motores
                  </b>{" "}
                  es una empresa dedicada al servicio y rectificación de motores
                  con más de 25 años de experiencia en el rubro.
                </p>
                <br />
                <p>
                  Dedicada a satisfacer las necesidades de nuestros clientes,
                  entregando un servicio personalizado, ágil y responsable.
                  Además contamos con una gran variedad de piezas de motor y
                  repuestos para motores Bencineros y Diesel.
                </p>
              </div>
            </div>
            <div className="col-md-6 imgquienes">
              <img src={require("../../images/team.jpg")} alt="" />
            </div>
          </div>
        </div>

        <div id="porque" className="rec">
          <div className="container">
            <div className="saas-section-title por-que">
              <div className="bar"></div>
              <h2>¿Por qué Preferirnos?</h2>
              <div className="bar"></div>
              <p>
                <b>Nos adaptamos a sus necesidades</b>, El repuesto que necesite
                lo gestionamos para usted
              </p>
            </div>
          </div>

          <OwlCarousel
            className="services-slides owl-carousel owl-theme"
            {...options}
          >
            {services.map((service, index) => {
              return this.renderService(service.name, service.image, index);
            })}
          </OwlCarousel>
        </div>
      </section>
    );
  }
}

export default About;
