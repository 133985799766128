import React from 'react'
import Navbar from '../components/Layouts/Navbar';
import Footer from '../components/Layouts/Footer';
import { Link } from "react-router-dom";

class NotFound extends React.Component {
    static getInitialProps({ res, err }) {
        const statusCode = res ? res.statusCode : err ? err.statusCode : null;
        return { statusCode };
    }

    render() {
        return (
            <React.Fragment>
                <Navbar />

                <section className="page-title-area jarallax" data-jarallax='{"speed": 0.2}'>
                    <div className="container">
                        <h2>Algo sali&oacute; mal... la p&aacute;gina que buscas no existe</h2>
                    </div>
                    <div className="main-banner-content">
                      <Link to="/" className="btn btn-primary">Ir al inicio</Link>
                    </div>
                </section>

                <section className="error-area ptb-100">
                    <div className="container">
                        <div className="error-content">
                            <img src={require("../images/404.png")} alt="error" />
                        </div>
                    </div>
                </section>

                <Footer />
            </React.Fragment>
        );
    }
}

export default NotFound;
