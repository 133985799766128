import React, { Component } from 'react';
import { ReactPhotoCollage } from "react-photo-collage";
import "./Gallery.css";

import image1 from '../../images/imgclientes/1.jpeg'
import image2 from '../../images/imgclientes/2.jpeg'
import image3 from '../../images/imgclientes/3.jpeg'
import image4 from '../../images/imgclientes/4.jpeg'
import image5 from '../../images/imgclientes/5.jpeg'
import image6 from '../../images/imgclientes/6.jpeg'
import image7 from '../../images/imgclientes/7.jpeg'
import image8 from '../../images/imgclientes/8.jpeg'
import image9 from '../../images/imgclientes/9.jpeg'
import image10 from '../../images/imgclientes/10.jpeg'
import image11 from '../../images/imgclientes/11.jpeg'
import image12 from '../../images/imgclientes/12.jpeg'
import image13 from '../../images/imgclientes/13.jpeg'
import image14 from '../../images/imgclientes/14.jpeg'
import image15 from '../../images/imgclientes/15.jpeg'




class Gallery extends Component {


    render() {
        const setting = {
            width: '100%',
            height: ['350px', '270px'],
            layout: [3, 3, 3],
            photos: [
                { source: image1 },
                { source: image2 },
                { source: image3 },
                { source: image4 },
                { source: image5 },
                { source: image6 },
                { source: image7 },
                { source: image8 },
                { source: image9 },
                { source: image10 },
                { source: image11 },
                { source: image12 },
                { source: image13 },
                { source: image14 },
                { source: image15 },

            ],
            showNumOfRemainingPhotos: true
        };

        return (
            <React.Fragment>
                <div id="gallery" className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="saas-section-title collage">
                                <div className="bar"></div>
                                <h2>Nuestros Clientes</h2>
                                <div className="bar"></div>
                                <p>
                                    En <b>Wmotor</b> la protección a nuestros clientes está garantizada, continuemos cuidándonos ante COVID-19
                                </p>
                            </div>
                            <div class="row">
                                <div class="col-sm">
                                    <div className="collge">
                                        <ReactPhotoCollage className="image" {...setting} />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Gallery;
