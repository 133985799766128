import React, { Component } from "react";
import config from "../../config";
import "./Contact.css";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      alertMessage: "",
      name: "",
      email: "",
      phone: "",
      message: "",
      isErrorName: false,
      isErrorEmail: false,
      isErrorPhone: false,
      isErrorMessage: false
    };
  }
  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }
  validate() {
    var errors = {};
    errors.isErrorName = this.state.name.length < 3;
    errors.isErrorEmail = this.state.email.length < 8;
    errors.isErrorPhone = this.state.phone.length < 8;
    errors.isErrorMessage = this.state.message.length < 3;
    errors.hasError = errors.isErrorName || errors.isErrorEmail || errors.isErrorPhone || errors.isErrorMessage;
    return errors;
  }
  handleSubmit = async event => {
    event.preventDefault();
    const errors = this.validate();
    if (errors.hasError) {
      errors.hasError = undefined;
      this.setState({...errors});
      return;
    }
    this.setState({ ...errors, isLoading: true });
    try {
      const response = await this.contact({
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        message: this.state.message
      });
      console.log(response);
      this.setState({ isLoading: false, isError: false, isSuccess: true, name: '', email: '', phone: '', message: '' });
    } catch (e) {
      console.error(e);
      this.setState({ isLoading: false, isError: true, isSuccess: false });
    }
  }
  contact(data) {
    return fetch(config.url.api + '/contact', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' }
      })
      .then(res => {
        //console.log(res.status);
      });
  }
  handleClose = (event) => {
    this.setState({isError: false, isSuccess: false});
  }

  render() {
    return (
      <div id="contact" className="contacto">
        <div
          className="fh5co-narrow-content animate-box fadeInLeft animated"
          data-animate-effect="fadeInLeft"
        >
          <div class="col-md-10 as">
            <h1 className="n">Contáctanos</h1>
          </div>
          <form onSubmit={this.handleSubmit}>
            <div className="row">
              <div class="col-md-12">
                <div className="row">
                  <div className="col-md-10">
                    <div className="form-group">
                      <input id="name" value={this.state.name} type="text" placeholder="Nombre" className={"form-control" + (this.state.isErrorName ? " is-invalid" : "")} required={true} data-error="Ingresa tu nombre" onChange={this.handleChange} />
                      <div className="help-block with-errors">
                      {
                        this.state.isErrorName &&
                        <div className="invalid-feedback">
                          Debes ingresar tu nombre
                        </div>
                      }
                      </div>
                      <input id="email" value={this.state.email} type="email" placeholder="E-mail" className={"form-control" + (this.state.isErrorEmail ? " is-invalid" : "")} required={true} data-error="Ingresa tu email" onChange={this.handleChange} />
                      <div className="help-block with-errors">
                      {
                        this.state.isErrorEmail &&
                        <div className="invalid-feedback">
                          Debes ingresar tu email
                        </div>
                      }
                      </div>
                      <input id="phone" value={this.state.phone} type="text" placeholder="Teléfono" className={"form-control" + (this.state.isErrorPhone ? " is-invalid" : "")} required={true} data-error="Ingresa tu teléfono" onChange={this.handleChange} />
                      <div className="help-block with-errors">
                      {
                        this.state.isErrorPhone &&
                        <div className="invalid-feedback">
                          Debes ingresar tu tel&eacute;fono
                        </div>
                      }
                      </div>
                      <textarea id="message" value={this.state.message} placeholder="Mensaje" className={"form-control" + (this.state.isErrorMessage ? " is-invalid" : "")} cols="30" rows="5" required={true} data-error="Escribe el mensaje" onChange={this.handleChange} />
                      <div className="help-block with-errors">
                      {
                        this.state.isErrorMessage &&
                        <div className="invalid-feedback">
                          Debes ingresar el mensaje
                        </div>
                      }
                      </div>
                      {
                        (this.state.isError || this.state.isSuccess) &&
                        <div className={"alert alert-" + (this.state.isError ? "danger" : "success") + " col-lg-12 col-md-12"} role="alert">
                          {this.state.isError ? "Error al enviar el mensaje, por favor intenta nuevamente." : "Mensaje enviado exitosamente. Nos comunicaremos contigo prontamente."}
                          <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={this.handleClose}>
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                      }
                      {
                        <React.Fragment>
                            <button type="submit" className="btn btn-primary btn-md" disabled={this.state.isLoading}>
                              {
                                this.state.isLoading ?
                                "Enviando..."
                                :
                                "Enviar Mensaje"
                              }
                            </button>
                            <div id="msgSubmit" className="h3 text-center hidden"></div>
                            <div className="clearfix"></div>
                        </React.Fragment>
                      }
                      <div class="form-group redes">
                        <div class="col-md-12 form-group redes">
                          <h3>Síguenos en nuestras redes</h3>
                          <div class="row">
                            <div
                              class="col-4 btn-group"
                              role="group"
                              aria-label="First group"
                            >
                              <a
                                href="https://www.facebook.com/Wmotorchilerepuestos"
                                target="_blank" rel="noopener noreferrer"
                              >
                                <button
                                  type="button"
                                  class="btn btn-outline-link facebook"
                                >
                                  <img alt=""
                                    src={require("../../images/Facebook.png")}
                                  />
                                  <span>Facebook</span>
                                </button>
                              </a>
                            </div>
                            <div
                              class="col-4 btn-group"
                              role="group"
                              aria-label="Second group"
                            >
                              <a
                                href="https://www.instagram.com/wmotorchile/?hl=es-la"
                                target="_blank" rel="noopener noreferrer"
                              >
                                <button
                                  type="button"
                                  class="btn btn-outline-link instagram"
                                >
                                  <img alt=""
                                    src={require("../../images/Instagram.png")}
                                  />
                                  <span>Instagram</span>
                                </button>
                              </a>
                            </div>
                            <div
                              class="col-4 btn-group"
                              role="group"
                              aria-label="First group"
                            >
                              <a
                                href="https://wa.me/+56994898180?text=Hola,%20Necesito%20M%C3%A1s%20Informaci%C3%B3n"
                                target="_blank" rel="noopener noreferrer"
                              >
                                {" "}
                                <button
                                  type="button"
                                  class="btn btn-outline-link whatsapp"
                                >
                                  <img alt="" src={require("../../images/wsp.png")} />
                                  <span>WhatsApp</span>
                                </button>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default Contact;
