import React, { Component } from "react";
import NavbarThree from "../components/Layouts/NavbarThree";
import Banner from "../components/Home/Banner";
import About from "../components/Home/About";
import Services from "../components/Home/Services";
import Gallery from "../components/Home/Gallery";
import Gmap from "../components/Home/Gmap";
import Footer from "../components/Layouts/Footer";

class Home extends Component {
  render() {
    return (
      <React.Fragment>
        <NavbarThree />
        <Banner />
        <About />
        <Services />
        <Gallery />
        <Gmap />
        <Footer />
      </React.Fragment>
    );
  }
}

export default Home;
