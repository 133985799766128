import React, { Component } from 'react';
import "./Gmap.css";

class Gmap extends Component {
    render() {
        return (
            <section id="map" className="about-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 desc">
                            <div className="saas-section-title gmap">
                                <h2>PARA MÁS INFORMACIÓN CONTACTANOS EN NUESTRAS REDES</h2>
                            </div>
                            <div className="row">
                                <div class="col insta"><a className="glow-on-hover" href="https://www.instagram.com/wmotorchile/?hl=es-la" target="_blank" rel="noopener noreferrer"><img alt="" className="imagen im1" src={require("../../images/insta.png")} /></a></div>
                            </div>
                            <div className="row">
                                <div class="col face"><a className="glow-on-hover" href="https://www.facebook.com/Wmotorchilerepuestos" target="_blank" rel="noopener noreferrer"><img alt="" className="imagen im2" src={require("../../images/face.png")} /></a></div>
                            </div>
                            <div className="row">
                                <div class="col whatsapp"><a className="glow-on-hover" href="https://wa.me/+56994898180?text=Hola,%20Necesito%20M%C3%A1s%20Informaci%C3%B3n" target="_blank" rel="noopener noreferrer"><img alt="" className="imagen im3" src={require("../../images/whatsapp.png")} /></a></div>
                            </div>
                            <div className="row">
                                <div class="col ubicacion"><a className="glow-on-hover" href="https://goo.gl/maps/j6CEtGWRa7dFzWh96" target="_blank" rel="noopener noreferrer"><img alt="" className="imagen im4" src={require("../../images/map2.png")} /></a></div>
                            </div>
                        </div>
                        <div className="col-md-6 mapa">
                            <div className="saas-section-title gmap">
                                <h2>TAMBIÉN NOS PUEDES VISITAR EN <br/> patricio Lynch #215,CHILLÁN, CHILE</h2>
                            </div>
                            <iframe title="Mapa" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d653.5604849475105!2d-72.09580861190446!3d-36.623169022403935!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x966929ccd9558999%3A0x59f8b2b1dd76c30!2sWmotorchile!5e1!3m2!1ses!2scl!4v1674579211185!5m2!1ses!2scl" width="100%" height="500" allowfullscreen="" loading="lazy"></iframe>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default Gmap;