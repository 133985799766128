import React, { Component } from 'react';
import "./Services.css";


class Services extends Component {
    state = { display:false};

    componentDidMount(){
        this.setState({ display: true })
    }

    render() {
        return ( 
            <section id="services" className="about-area ptb-100">
            <div className="container">    
              <div className="saas-section-title service">
                <div className="bar"></div>
                <h2>Reparación y Rectificación de Motores</h2>
                <div className="bar"></div>
                <p className="sub-title">
                <b>WMotorChile</b>{" "}
                    realiza un servicio integral de reparación y rectificación de motores
                </p>
                <br />
               <div className="row align-items-center">
                  <div className="about-content">
                    <div className="row car"> 
                    <div className="col-md-6">
                      <div class="blog-card">
                        <div class="meta">
                          <div class="photo">
                            <img alt="" src={require("../../images/4.jpg")} />
                          </div>
                        </div>
                        <div class="description">
                          <h1><b>Rectificación de Motores de Blocks y Culatas</b></h1>
                          <p>Bencineros y Diesel</p>
                          <br></br>             
                        </div>
                      </div>
                      </div>
                      <div className="col-md-6">
                      <div class="blog-card alt">
                        <div class="meta">
                          <div class="photo">
                            <img alt="" src={require("../../images/motor1.jpg")} />
                          </div>
                        </div>
                        <div class="description">
                          <h1><b>Pruebas Hidráulicas de Culatas</b></h1>
                          <p>Revisión y Diagnóstico de fisuras sometidas a sobrepresión</p>
                        </div>
                      </div>
                      </div>
                      
                    </div>
    
                    <div className="row car"> 
                    <div className="col-md-6">
                      <div class="blog-card">
                        <div class="meta">
                          <div class="photo">
                            <img alt="" src={require("../../images/motor2.jpg")} />
                          </div>
                        </div>
                        <div class="description">
                          <h1><b>Regulación de Culatas</b></h1>
                          <p>Revisión, verificación y regulado de Válvulas en Culatas Bencineras y Diesel</p>
                        </div>
                      </div>
                      </div>
                      <div className="col-md-6">
                      <div class="blog-card alt">
                        <div class="meta">
                          <div class="photo">
                            <img alt="" src={require("../../images/motor3.jpg")} />
                          </div>
                        </div>
                        <div class="description">
                          <h1><b>Recuperación y Recambio de Culatas</b></h1>
                          <p>Rectificación y Armado de Culatas</p>
                        </div>
                      </div>
                      </div>
                      
                    </div>
                    <div className="row car"> 
                    <div className="col-md-6">
                      <div class="blog-card">
                        <div class="meta">
                          <div class="photo">
                            <img alt="" src={require("../../images/motor4.jpg")} />
                          </div>
                        </div>
                        <div class="description">
                        <h1><b>Rectificación de Válvulas y Asientos de Culatas</b></h1>
                          <p>Bencineros y Diesel Rectificación y Armado de Culatas</p>
                        </div>
                      </div>
                      </div>
                      <div className="col-md-6">
                      <div class="blog-card alt">
                        <div class="meta">
                          <div class="photo">
                            <img alt="" src={require("../../images/motor5.jpg")} />
                          </div>
                        </div>
                        <div class="description">
                          <h1><b>Rectificación de Cilindros y Encamisado</b></h1>
                          <p>Bencineros y Diesel Rectificado de Block y Cambio de Camisas Standar y Sobremedida.</p>
                        </div>
                      </div>
                      </div>
                      
                    </div>
                    <div className="row car"> 
                    <div className="col-md-6">
                      <div class="blog-card">
                        <div class="meta">
                          <div class="photo">
                            <img alt="" src={require("../../images/motor6.jpg")} />
                          </div>
                        </div>
                        <div class="description">
                          <h1>Rectificación de Cigüeñales, Bielas y Eje de Levas</h1>
                          <p>Bencineros y Diesel</p>
                        </div>
                      </div>
                      </div>                
                    </div>
                  </div>
                </div>
              
            </div>
        </div>
        </section>
        );
    }
}

export default Services;
