import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

class Footer extends Component {
  render() {
    return (
      <footer className="footer-area bg-fffcf4">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="single-footer-widget">
                <div className="logo">
                  <Link to="/">
                    <img
                      className="logo-img"
                      src={require("../../images/logowmotors.png")}
                      alt=""
                    />
                  </Link>
                </div>

                <ul className="contact-info">
                  <li>Patricio Lynch #215, Chillán, Chile</li>
                  <li>
                    <a href="mailto:wmotorchile@gmail.com">
                      wmotorchile@gmail.com
                    </a>
                  </li>
                  <li>
                    <a href="mailto:contacto@wmotorchile.com">
                      contacto@wmotorchile.com
                    </a>
                  </li>
                  <li>
                    <Link to="/">www.wmotorchile.cl</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="single-footer-widget cotice">
                <a
                  target="blank"
                  href="https://api.whatsapp.com/send/?phone=%2B56994898180&text=Hola%2C+WMOTOR+CHILE+Necesito+Realizar+Una+Cotizaci%C3%B3n&app_absent=0"
                >
                  <img src={require("../../images/cotice.jpg")} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="copyright-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7 col-md-7">
                <p>
                  <i className="far fa-copyright"></i> 2023{" "}
                  <Link to="/">Wmotor Chile</Link>. Todos los derechos
                  reservados
                </p>
              </div>
              <div className="col-lg-5 col-md-5">
                <p style={{ textAlign: "center" }}>
                  Sitio desarrollado por{" "}
                  <a
                    href="https://www.neokode.cl"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Neokode
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
